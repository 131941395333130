.title {
  text-align: center;
  width: 100%;
  margin-bottom: 20px;
}

.form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px solid #000000;
  margin: 10px;
}

.fuel-quote-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.form-group {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
}

.form-group label {
  width: 180px;
  margin-right: 10px;
  text-align: left;
}

.form-group input {
  flex: 1;
  width: 250px;
  margin-right: 10px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: white;
}

.retrieve-address {
  width: 100%;
  min-width: 250px;
}

.responsive-image {
  width: 65%;
  height: auto;
  margin: 0 auto;
  display: block;
}

/* limit the size of the image */
@media (min-width: 768px) {
  .responsive-image {
    max-height: 700px;
  }
}

/* Footer.css */
.footer {
  background-color: #141239;
  color: #cfcfcf;
  text-align: center;
  padding: 10px 0;
  width: 100%;
  margin-top: auto;
}

.container {
  max-width: 960px;
  margin: 0 auto;
}

/* Header.css */
header {
  background-color: #e4f2fe;
}

.profile-table-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.two-column-layout {
  display: flex;
}

.image-column {
  flex: 2;
  padding: 20px;

}

.form-column {
  flex: 1;
  padding: 20px;
}


img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.login-container {
  display: flex;
  min-height: 100vh;
  align-items: stretch;
  flex-direction: row;
  /* Ensure the children fill the height */
}

.login-image-section {
  flex: 1.2;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* Center children horizontally */
  justify-content: center;
  /* Center children vertically */
  background-color: #20d489;
  color: white;
}

.image-text-content h2 {
  font-size: 3rem;
  margin-top: 15px;
  color: #181c32;
  font-family: "Lexend", sans-serif;
  font-weight: bold;
}

.image-text-content p {
  font-size: 1.4rem;
  color: white;
  font-weight: bold;
  font-family: "Lexend", sans-serif;
}

.login-image {
  max-width: 80%;
  height: auto;
  margin-top: 0.5rem;
}

.login-form-section {
  flex: 1;
  display: flex;
  justify-content: center;
  padding: 1rem;
  background-color: white;
  align-items: center;
}

.login-form-content {
  max-width: 450px;
  /* Maximum width of the form */
  width: 90%;
  /* Make the form full width within its container */
  text-align: left;
  font-family: "Lexend", sans-serif;
}

.login-form-content h1 {
  margin-bottom: 1em;
  color: #181c32;
}

.login-form-content p {
  margin-bottom: 3em;
  color: #ababab;
  font-family: "Lexend", sans-serif;
  font-weight: bold;
}

.login-form-group {
  margin-bottom: 1em;
}

.login-form-group input {
  border: 0px solid #ccc;
  border-radius: 10px;
  padding: 10px 15px;
  margin-bottom: 20px;
  /* Space between each input field */
  width: 100%;
  box-sizing: border-box;
  /* Include padding and border in the element's width */
  font-size: 14px;
  background-color: #eef3f7;
  font-family: "Lexend", sans-serif;
}

.login-form-group input::placeholder {
  color: #aaa;
}

.login-form-group input:focus {
  border-color: #9fa7a8;
  outline: none;
  /* Remove default focus outline */
  box-shadow: none;
}

.login-form-group a {
  display: block;
}

.login-form-group label {
  font-weight: bold;
  font-size: 15px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .login-container {
    flex-direction: column;
    /* Stack image and form vertically on smaller screens */
  }

  .login-image-section,
  .login-form-section {
    flex: none;
    /* Remove the flex grow/shrink ratios */
    width: 100%;
  }

  .login-image {
    max-width: 70%;
  }

  .login-form-content {
    padding: 1rem;
    /* Add more padding around the form for smaller screens */
  }
}

a {
  color: #20d489;
  text-decoration: none;
}

Link {
  color: #20d489;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.login-form-content button {
  width: 100%;
  font-family: "Lexend", sans-serif;
  font-size: 14px;
}

button {
  padding: 10px;
  margin-bottom: 1em;
  background-color: #20d489;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
}

button:hover {
  background-color: #19c17b;
}


.button-container {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  text-align: center;
  width: 100%;
  gap: 10px;

}

.error-msg {
  display: flex;
  color: rgb(254, 62, 62);
  font-size: 12px;
  justify-content: flex-start;
  padding-left: 5px;
  margin-top: 2px;
}

.table-responsive-cell {
  word-wrap: break-word;
  /* Allows the words to break and wrap to the next line */
  max-width: 300px;
  /* Adjust this value based on your layout's needs */
  overflow-wrap: break-word;
  /* Ensures that long words will wrap and not overflow */
}

.navbar-toggler:hover {
  background-color: rgb(215, 215, 215);
}

.navbar-flex-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

/* Responsive behavior */
@media (max-width: 768px) {
  .navbar-flex-container {
    flex-direction: column;
  }
}

.password-checklist li {
  font-size: 12px;
  line-height: 1.2;
}

.password-checklist svg {
  width: 10px;
  height: 1.2;
}

.password-checklist {
  column-count: 1;
  column-gap: 10px;
}


.password-checklist li {
  break-inside: avoid;
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
}